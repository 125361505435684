<!--
 * @Description: 新闻列表-卡片(项目动态  机构动态)
 * @Author: Chp
 * @Date: 2021-04-02 14:21:01
 * @LastEditTime: 2021-06-01 17:07:18
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="news-card">
    <!-- 浩萍代码 -->
    <img
      class="cover"
      :src="source == 'OtherInfo' ? info.contentImg[0].url : info.contentUrl"
      alt="封面图"
      @click="goArticle"
    />
    <div class="info">
      <h3 class="title" @click="goArticle">{{ info.contentTitle }}</h3>
      <h5 class="intro">{{ info.contentDescription }}</h5>
      <div class="bottom" v-if="source == 'Project' || source == 'Mechanism'">
        <div class="tags" v-if="source == 'Project'">
          <span
            @click.stop="tagClick(i.tagsId)"
            v-for="i in info.results"
            :key="i.tagsId"
            >{{ i.tagsName }}</span
          >
        </div>
        <p class="time">{{ info.contentDatetime.substr(0, 10) }}</p>
      </div>
      <div class="bottom" v-if="source == 'OtherInfo'">
        <div class="otherInfo">
          <div class="time">{{ info.contentDatetime.substr(0, 10) }}</div>
          <div class="file">
            {{ info.fileName }}
            <!-- <a :href="downLoad()" download @click.stop="">下载相关附件</a> -->
            <form :action="downLoad()" method="post">
              <button type="submit">下载相关附件</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * info:详情
   * source:来源  Project，Mechanism,OtherInfo
  */
  props: ['info', 'source'],
  data () {
    return {
      tagsList: []
    }
  },
  created () {
  },
  methods: {
    tagClick (tagsId) {
      console.log('子组件发送', tagsId)
      this.$emit('tagClick', tagsId)
    },
    goArticle () {
      this.contentHit();
      if (this.source == 'Project') {//项目动态
        this.$router.push({ path: '/news/project/proArticle', query: { id: this.info.id } })
      } else if (this.source == 'Mechanism') {//机构动态
        this.$router.push({ path: '/news/mechanism/mecArticle', query: { id: this.info.id } })
      } else if (this.source == 'OtherInfo') {//其它信息
        this.$router.push({ path: '/openInfomation/otherInfo/article', query: { id: this.info.id } })
      }
    },
    downLoad () {
      const { fileName, id } = this.info
      let url = `${process.env.VUE_APP_BASE_API}/cms/oss/download/${id}/${fileName}.do`
      return url
    },
    contentHit () {
      this.$api.contentHit(this.info.id).then(() => {
        console.log('点击量+1')
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.news-card {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #ebedf0;
  &:first-child {
    padding-top: 0px;
  }
  .cover {
    width: 282px;
    height: 172px;
    margin-right: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .info {
    flex: 1;
    position: relative;
    .title {
      cursor: default;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: $main-color;
      }
    }
    .intro {
      font-size: 14px;
      color: #999999;
      margin-top: 6px;
      font-weight: normal;
    }
    .tags {
      font-size: 14px;
      color: #e25554;
      span {
        margin-right: 12px;
        text-decoration: underline;
        cursor: default;
        &:hover {
          color: $main-color;
        }
      }
    }
    .time {
      font-size: 12px;
      color: #bdbdbd;
    }
    .bottom {
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      .otherInfo {
        display: flex;
        .time {
          flex: 1;
        }
        .file {
          flex: 4;
          text-align: right;
          font-size: 14px;
          color: #666;
          form {
            display: inline-block;
          }
          a,
          button {
            color: #e25554;
            cursor: default;
            padding-left: 22px;
            margin-left: 20px;
            text-decoration: none;
            background: url(~@/assets/images/icon/icon_xiazai_a.png) no-repeat
              center left;
            background-size: 18px 18px;
            display: inline-block;
            height: 18px;
            height: 18px;
            border: none;
            &:hover {
              color: $main-color;
              background: url(~@/assets/images/icon/icon_xiazai_b.png) no-repeat
                center left;
              background-size: 18px 18px;
            }
          }
        }
      }
    }
  }
}
</style>