/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-04-01 17:48:58
 * @LastEditTime: 2021-05-12 14:47:35
 * @LastEditors: Chp
 * @Reference:
 */
import LogoTitle from './LogoTitle.vue'
import Breadcrumb from './Breadcrumb.vue'
import NewsCard from './NewsCard.vue'
import SinglePagination from './SinglePagination.vue'
import AlbumCard from './AlbumCard.vue'
import RichArticle from './RichArticle.vue'
import ArticleTitle from './ArticleTitle.vue'
import FileList from './FileList.vue'

function plugins(Vue) {
  Vue.component('LogoTitle', LogoTitle) //正文标题  logo+标题
  Vue.component('Breadcrumb', Breadcrumb) //面包屑
  Vue.component('NewsCard', NewsCard) //动态卡片
  Vue.component('SinglePagination', SinglePagination) //单页分页器
  Vue.component('AlbumCard', AlbumCard) //活动图集卡片
  Vue.component('RichArticle', RichArticle) //富文本渲染
  Vue.component('ArticleTitle', ArticleTitle) //文章标题
  Vue.component('FileList', FileList) //文件列表
}

export default plugins
