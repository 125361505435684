<template>
  <div class="single-pagination">···1页，共{{ total }}条···</div>
</template>

<script>
export default {
  props: ['total'],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.single-pagination {
  text-align: center;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: bold;
}
</style>