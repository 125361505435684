<!--
 * @Descripttion: 活动图集-卡片
 * @version: 
 * @Author: Chp
 * @Date: 2021-04-06 16:38:43
 * @LastEditors: Chp
 * @LastEditTime: 2021-05-25 16:03:41
-->

<template>
  <div class="album-card">
    <div class="img-box">
      <img class="img" :src="info.contentUrl" alt="" />
      <div class="mask" @click="goArticle">
        <div class="total">共{{ totalPic }}张</div>
        <div class="more">查看更多</div>
      </div>
    </div>
    <div class="name" @click="goArticle">{{ info.contentTitle }}</div>
    <div class="tags">
      <span></span>
      <span
        @click="tagClick(i.tagsId)"
        v-for="i in info.results"
        :key="i.tagsId"
        >{{ i.tagsName }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlbumCard',
  props: ['info'],
  data () {
    return {
      tagsList: [],//标签
      totalPic: 0 //共xx张
    }
  },
  created () {
    //将标签转为数组
    if (this.info.contentType.search(",") != -1) {
      this.tagsList = this.info.contentType.split(',')
    } else {
      this.tagsList.push(this.info.contentType)
    }

    this.totalPic = JSON.parse(this.info.contentImg).length;
  },
  methods: {
    goArticle () {
      this.$router.push({ path: '/news/album/albumArticle', query: { id: this.info.id } })
    },
    tagClick (tagsId) {
      console.log('子组件发送', tagsId)
      this.$emit('tagClick', tagsId)
    },
  },
}
</script>

<style scoped lang='scss'>
.album-card {
  display: inline-block;
  //   margin-right: 34px;
  margin-left: 20px;
  margin-right: 14px;
  text-align: center;
  margin-bottom: 35px;
  .img-box {
    position: relative;
    &:hover {
      .mask {
        display: block;
      }
    }
    .img {
      width: 280px;
      height: 278px;
      border-radius: 50%;
      background: #d8d8d8;
      display: block;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 280px;
      height: 278px;
      border-radius: 50%;
      background: rgba(229, 1, 19, 0.8);
      color: #fff;
      display: none;
      cursor: pointer;
      .total {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 108px;
      }
      .more {
      }
    }
  }

  .name {
    font-weight: bold;
    color: #333333;
    font-size: 16px;
    margin-top: 12px;
    cursor: pointer;
    &:hover {
      color: $main-color;
    }
  }
  .tags {
    margin-top: 4px;
    span {
      color: #e25554;
      font-size: 14px;
      margin-right: 8px;
      text-decoration: underline;
      cursor: default;
      display: inline-block;
      height: 23px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: $main-color;
      }
    }
  }
}
</style>