import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  //   {
  //     path: '/about',
  //     name: 'About',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/',
    component: () => import('../components/Layout/Layout.vue'),
    meta: {
      title: '首页', //整体页面布局
    },
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/index.vue'),
        meta: {
          title: '首页',
        },
      },
      {
        path: 'pubilcWelfare',
        name: 'PublicWelfare',
        component: () => import('../views/publicWelfare/index.vue'),
        redirect: '/pubilcWelfare/publictItem',
        meta: {
          title: '公益项目',
        },
        children: [
          {
            path: 'publictItem',
            name: 'PublictItem',
            component: () => import('../views/publicWelfare/publicItem.vue'),
            meta: {
              title: '项目详情',
              parentName: 'PublicWelfare',
            },
          },
        ],
      },
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () => import('../views/aboutUs/index.vue'),
        meta: {
          title: '关于我们',
        },
        redirect: '/aboutUs/intro', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'intro',
            name: 'Intro',
            component: () => import('../views/aboutUs/introduction.vue'),
            meta: {
              title: '基金会介绍',
              parentName: 'AboutUs',
            },
          },
          {
            path: 'framework',
            name: 'Framework',
            component: () => import('../views/aboutUs/framework.vue'),
            meta: {
              title: '组织结构',
              parentName: 'AboutUs',
            },
          },
          {
            path: 'council',
            name: 'Council',
            component: () => import('../views/aboutUs/council.vue'),
            meta: {
              title: '理事会',
              parentName: 'AboutUs',
            },
          },
          {
            path: 'rule',
            name: 'Rule',
            component: () => import('../views/aboutUs/rule.vue'),
            meta: {
              title: '理事会章程',
              parentName: 'AboutUs',
            },
          },
          {
            path: 'contactUs',
            name: 'ContactUs',
            component: () => import('../views/aboutUs/contactUs.vue'),
            meta: {
              title: '联系我们',
              parentName: 'AboutUs',
            },
          },{
            path: 'institutionalHonor',
            name: 'institutionalHonor',
            component: () => import('../views/aboutUs/institutionalHonor.vue'),
            meta: {
              title: '机构荣誉',
              parentName: 'AboutUs',
            },
          },
        ],
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('../views/news/index.vue'),
        meta: {
          title: '新闻动态',
        },
        redirect: '/news/mechanism', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children: [
          {
            path: 'mechanism',
            name: 'Mechanism',
            component: () => import('../views/news/mechanism/index.vue'),
            meta: {
              title: '机构动态',
              parentName: 'News',
            },
            redirect: '/news/mechanism/mecList',
            children: [
              {
                path: 'mecList',
                name: 'MecList',
                component: () =>
                  import('../views/news/mechanism/mechanism.vue'),
                meta: {
                  title: '',
                  parentName: 'News',
                },
              },
              {
                path: 'mecArticle',
                name: 'MecArticle',
                component: () =>
                  import('../views/news/mechanism/mecArticle.vue'),
                meta: {
                  title: '正文',
                  parentName: 'News',
                },
              },
            ],
          },
          {
            path: 'project',
            name: 'Project',
            redirect: '/news/project/ProList',
            component: () => import('../views/news/project/index.vue'),
            meta: {
              title: '项目动态',
              parentName: 'News',
            },
            children: [
              {
                path: 'ProList',
                name: 'ProList',
                component: () => import('../views/news/project/projec.vue'),
                meta: {
                  title: '',
                  parentName: 'News',
                },
              },
              {
                path: 'proArticle',
                name: 'ProArticle',
                component: () => import('../views/news/project/proArticle.vue'),
                meta: {
                  title: '正文',
                  parentName: 'News',
                },
              },
            ],
          },
          {
            path: 'album',
            name: 'Album',
            redirect: '/news/album/albumList',
            component: () => import('../views/news/album/index.vue'),
            meta: {
              title: '活动图集',
              parentName: 'News',
            },
            children: [
              {
                path: 'albumList',
                name: 'AlbumList',
                component: () => import('../views/news/album/album.vue'),
                meta: {
                  title: '',
                  parentName: 'News',
                },
              },
              {
                path: 'albumArticle',
                name: 'AlbumArticle',
                component: () => import('../views/news/album/albumArticle.vue'),
                meta: {
                  title: '正文',
                  parentName: 'News',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/openInfomation',
        name: 'OpenInfomation',
        component: () => import('../views/openInformation/index.vue'),
        meta: {
          title: '信息公开',
        },
        redirect: '/openInfomation/annualReport',
        children: [
          {
            path: 'annualReport',
            name: 'AnnualReport',
            component: () =>
              import('../views/openInformation/annualReport.vue'),
            meta: {
              title: '年检年报',
              parentName: 'OpenInfomation',
            },
          },
          {
            path: 'auditReport',
            name: 'AuditReport',
            component: () => import('../views/openInformation/auditReport.vue'),
            meta: {
              title: '审计报告',
              parentName: 'OpenInfomation',
            },
          },
          {
            path: 'planSummary',
            name: 'PlanSummary',
            component: () => import('../views/openInformation/planSummary.vue'),
            meta: {
              title: '计划总结',
              parentName: 'OpenInfomation',
            },
          },
          {
            path: 'mechanismSystem',
            name: 'MechanismSystem',
            component: () =>
              import('../views/openInformation/mechanismSystem.vue'),
            meta: {
              title: '机构制度',
              parentName: 'OpenInfomation',
            },
          },
          {
            path: 'donationInfo',
            name: 'DonationInfo',
            component: () =>
              import('../views/openInformation/donationInfo.vue'),
            meta: {
              title: '捐款信息公示',
              parentName: 'OpenInfomation',
            },
          },
          {
            path: 'otherInfo',
            name: 'OtherInfo',
            redirect: '/openInfomation/otherInfo/list',
            component: () =>
              import('../views/openInformation/otherInfo/index.vue'),
            meta: {
              title: '其它信息',
              parentName: 'OpenInfomation',
            },
            children: [
              {
                path: 'list',
                name: 'OrderInfoList',
                component: () =>
                  import('../views/openInformation/otherInfo/list.vue'),
                meta: {
                  title: '',
                  parentName: 'OpenInfomation',
                },
              },
              {
                path: 'article',
                name: 'OrderInfoArticle',
                component: () =>
                  import('../views/openInformation/otherInfo/article.vue'),
                meta: {
                  title: '正文',
                  parentName: 'OpenInfomation',
                },
              },
            ],
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
})

export default router
