import { render, staticRenderFns } from "./LogoTitle.vue?vue&type=template&id=586d0019&scoped=true&"
import script from "./LogoTitle.vue?vue&type=script&lang=js&"
export * from "./LogoTitle.vue?vue&type=script&lang=js&"
import style0 from "./LogoTitle.vue?vue&type=style&index=0&id=586d0019&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586d0019",
  null
  
)

export default component.exports