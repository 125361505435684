<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-08 15:51:10
 * @LastEditTime: 2021-05-21 09:03:19
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="article-title">
    <div class="h1">{{ info.contentTitle }}</div>
    <div class="time">
      {{ info.contentDatetime ? info.contentDatetime.substr(0, 10) : "" }}
    </div>
    <div class="tags" v-if="source == 'Project'">
      <span v-for="i in info.contentTag" :key="i.id">{{ i.tagName }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ArticleTitle',
  /**
   * @description: source:来源  Project，Mechanism,OtherInfo
   */
  props: ['info', 'source'],
  data () {
    return {
      tagsList: []
    }
  },
  created () {

  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.article-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebedf0;
  .h1 {
    color: #666;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .time {
    font-size: 12px;
    color: #bdbdbd;
  }
  .tags {
    font-size: 14px;
    color: #e25554;
    margin-top: 10px;
    span {
      margin-right: 12px;
      text-decoration: underline;
      cursor: default;
      &:hover {
        color: $main-color;
      }
    }
  }
}
</style>