<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-12 14:45:39
 * @LastEditTime: 2021-06-18 16:14:29
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="file-list">
    <!-- 列表 -->
    <div class="inspect-list">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="name" @click="preView(item.contentUrl)">
          {{ item.contentTitle }}
        </div>
        <div class="tool">
          <span class="icon preview" @click="preView(item.contentUrl)"
            >预览</span
          >
          <!-- <span class="icon download" @click="downLoad(item)">下载</span> -->
          <!-- <a class="icon download" :href="downLoad(item)" download>下载</a> -->
          <form :action="downLoad(item)" method="post">
            <button class="icon download" type="submit">下载</button>
          </form>
          <span class="time">{{
            item.contentDatetime ? item.contentDatetime.substr(0, 10) : ""
          }}</span>
        </div>
      </div>
    </div>
    <!-- 分页器 -->
    <div class="sljr-pagination">
      <SinglePagination
        :total="total"
        v-if="total <= pageSize"
      ></SinglePagination>
      <el-pagination
        :hide-on-single-page="isHide"
        :page-size="pageSize"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import utils from '@/utils'
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      pageSize: 10,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      categoryId: '',
      list: [],
    }
  },
  created () {
    let name = this.$route.name;
    if (name == "AnnualReport") {//年检年报
      this.categoryId = '1376793330951819266'
    } else if (name == "AuditReport") {//审计报告
      this.categoryId = '1376793430734311425'
    } else if (name == "PlanSummary") {//计划总结
      this.categoryId = '1376793450883747841'
    } else if (name == 'MechanismSystem') {//机构制度
      this.categoryId = '1376793467321225218'
    }
    this.getList();
  },
  methods: {
    /**
    * @description: 列表
    * @param  {categoryId: '',categoryType,pageNo,pageSize}
    * @return {*}
    */
    getList () {
      let params = {
        categoryId: this.categoryId,
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
        this.list.forEach(item => {
          if (item.contentUrl) {
            //截取文件名
            let fileName = item.contentUrl.split('/').pop()
            item.fileName = fileName;
          }
        })
        console.log(this.list)
      })
    },
    /**
     * @description: 预览
     */
    preView (url) {
      const fileType = url.split('.').pop();
      console.log(fileType)
      if (fileType != 'pdf') {
        this.$notify({
          title: '温馨提示',
          message: '仅支持PDF预览'
        });
      } else {
        window.open(url)
      }
    },
    downLoad (item) {
      const { fileName, id } = item
      //   let url = `http://www-staging.sljrfoundation.org/sljr/cms/oss/download/${id}/${fileName}.do`
      let url = `${process.env.VUE_APP_BASE_API}/cms/oss/download/${id}/${fileName}.do`
      //   window.open(url)
      return url
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList()
      document.body.scrollIntoView()
    }
  },
}
</script>

<style scoped lang='scss'>
.inspect-list {
  .item {
    padding: 20px 0;
    border-bottom: 1px solid #ebedf0;
    display: flex;
    .name {
      flex: 1;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: $main-color;
      }
    }
    .tool {
      font-size: 14px;
      color: #e25554;
      form {
        display: inline-block;
        margin-right: 30px;
        vertical-align: middle;
      }
      span,
      button,
      a {
        margin-right: 30px;
        color: #e25554;
        display: inline-block;
        height: 18px;
        line-height: 18px;
        border: none;
        &:last-child {
          margin-right: 0;
        }
        &.preview {
          background: url(../../assets/images/icon/icon_yulan_a.png) no-repeat
            center left;
          background-size: 18px 18px;
        }
        &.download {
          background: url(../../assets/images/icon/icon_xiazai_a.png) no-repeat
            center left;
          background-size: 18px 18px;
        }
        &.icon {
          cursor: default;
          padding-left: 25px;
          &:hover {
            color: $main-color;
            text-decoration: none;
            &.preview {
              background: url(../../assets/images/icon/icon_yulan_b.png)
                no-repeat center left;
              background-size: 18px 18px;
            }
            &.download {
              background: url(../../assets/images/icon/icon_xiazai_b.png)
                no-repeat center left;
              background-size: 18px 18px;
            }
          }
        }
      }
      .time {
        font-size: 12px;
        color: #bdbdbd;
      }
    }
  }
}
</style>
