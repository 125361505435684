/*
 * @Description:api接口
 * @Author: Chp
 * @Date: 2021-04-09 15:51:45
 * @LastEditTime: 2021-06-17 16:30:51
 * @LastEditors: Chp
 * @Reference:
 */

import request from './request'
import QS from 'qs'

/**
 * @description:获取友情链接
 * @param  {categoryId:1379341893564055553}
 * @return {*}
 */
const getFriendLinks = () => {
  let p = {
    categoryId: '1379341893564055553',
  }
  return request({
    url: '/cms/category/list.do',
    method: 'POST',
    data: QS.stringify(p),
  })
}

/**
 * @description: 获取列表
 * @param categoryId=>
 *      banner: 1377185864214577154
 *      新闻动态(首页): 1376791966871883777
 *      项目动态: 1376792241594601473
 *      活动图集: 1376792888784097281
 *      机构动态: 1376792129237585921
 * 信息公开:1376793188848799746
 * 捐款信息公示:1376793764999368706
 * 其他信息:1376793891675738114
 * 机构制度:1376793467321225218
 * 计划总结:1376793450883747841
 * 审计报告:1376793430734311425
 * 年检年报:1376793330951819266
 * @return {*}
 */
const getContentList = (data) => {
  return request({
    url: '/cms/content/list.do',
    method: 'POST',
    data: QS.stringify(data),
  })
}
/**
 * @description: 首页新闻动态（过滤活动图集）
 * @param  {*}
 * @return {*}
 */
const getNews = (data) => {
  return request({
    url: '/cms/content/getNews.do',
    method: 'POST',
    data: QS.stringify(data),
  })
}
/**
 * @description: 公益项目列表
 * @param  {categoryId:'1376794802837950466'}
 * @return {*}
 */
const getPublicWelfare = (data) => {
  return request({
    url: '/cms/category/list.do',
    method: 'POST',
    data: QS.stringify(data),
  })
}

/**
 * @description: 获取栏目详情
 * @param  categoryId
 * 基金会介绍:1376790390337867778
 * 联系我们:1376791146797371393
 * 理事会:1376790569174601730
 * 基金会章程:1376790851161853953
 * 组织架构:1376790496357289986
 * 公益项目正文:id
 * @return {*}
 * @param {*} params
 */
const getCategoryDetail = (params) => {
  return request({
    url: '/ms/cms/content/getFromFengMian.do',
    method: 'GET',
    params,
  })
}

/**
 * @description: 获取各个内容的详细信息
 * @param  {id}
 * @return {*}
 */
const getDetail = (params) => {
  return request({
    url: '/ms/cms/content/get',
    method: 'GET',
    params,
  })
}

/**
 * @description: 获取捐款统计信息
 * @param  {*}
 * @return {*}
 */
const getDonateInfo = (params) => {
  return request({
    url: '/cms/content/getDonateInfo.do',
    method: 'GET',
    params,
  })
}

/**
 * @description:获取一级导航头图
 * @param  {*}
 * @return {*}
 */
const getHeadPicture = (params) => {
  return request({
    url: '/ms/cms/category/get.do',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询文章详情-项目动态相关联列表
 * @param  {*}
 * @return {*}
 */
const getContentTagsList = (params) => {
  return request({
    url: '/ms/cms/content/getContentTagsList',
    method: 'GET',
    params,
  })
}
/**
 * @description: 增加点击量(用户浏览次数)
 * @param {*} contentId
 */
const contentHit = (contentId) => {
  return request({
    url: `/cms/content/${contentId}/hit`,
    method: 'GET',
  })
}

export default {
  getFriendLinks,
  getContentList,
  getPublicWelfare,
  getCategoryDetail,
  getDetail,
  getDonateInfo,
  getHeadPicture,
  getContentTagsList,
  contentHit,
  getNews,
}
