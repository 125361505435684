<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-02 10:38:26
 * @LastEditTime: 2021-06-01 16:08:41
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <!-- 面包屑 :class="{ active: index == routeMatched.length - 1 }"-->
  <div class="breadcrumb">
    <span
      v-for="(item, index) in routeMatched"
      :key="index"
      @click="clickBread(item, index)"
      >{{ item.meta.title }}<i class="el-icon-arrow-right"></i
    ></span>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data () {
    return {
      routeMatched: []
    }
  },
  created () {
    this.routeMatched = this.getTitle(this.$route.matched)
  },
  methods: {
    clickBread (item, index) {
      if (index == this.routeMatched.length - 1) return //最后一个无需跳转
      let path = item.path ? item.path : '/index'
      this.$router.push({ path: path })
    },
    getTitle (matched) {
      let arr = []
      matched.forEach(element => {
        if (element.meta.title) {
          arr.push(element)
        }
      });
      return arr;
    }
  },
  watch: {
    $route (newVal) {
      this.routeMatched = this.getTitle(newVal.matched);
    }
  }
}
</script>

<style scoped lang='scss'>
.breadcrumb {
  font-size: 18px;
  color: #999;
  margin-top: 30px;
  margin-bottom: 40px;
  span {
    cursor: pointer;
    &:last-child {
      color: #333333;
      cursor: auto;
      .el-icon-arrow-right {
        display: none;
      }
    }
  }
  //   .active {
  //     color: #333333;
  //     cursor: auto;
  //     .el-icon-arrow-right {
  //       display: none;
  //     }
  //   }
}
</style>