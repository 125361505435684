<template>
  <div class="logo-title">
    <img class="logo" src="~@/assets/images/logo_02.png" alt="" />
    <span class="title">{{ msg }}</span>
  </div>
</template>

<script>
export default {
  name: 'LogoTitle',
  props: ['msg'],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.logo-title {
  margin-bottom: 20px;
}
.logo {
  width: 46px;
  height: 48px;
  vertical-align: middle;
}
.title {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-left: 10px;
  vertical-align: middle;
}
</style>