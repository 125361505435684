/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-03-23 13:54:34
 * @LastEditTime: 2021-04-14 15:04:25
 * @LastEditors: Chp
 * @Reference:
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    publicWelfareList: [], //公益项目列表
    concactUs: {}, //联系我们
  },
  getters: {
    publicWelfareList(state) {
      return state.publicWelfareList
    },
    concactUs(state) {
      return state.concactUs
    },
  },
  mutations: {
    updatePublicWelfareList(state, data) {
      state.publicWelfareList = data
    },
    updateConcactUs(state, data) {
      state.concactUs = data
    },
  },
  actions: {},
  modules: {},
})
