/*
 * @Description:
 * @Author: Chp
 * @Date: 2021-03-23 13:54:34
 * @LastEditTime: 2021-05-13 10:04:35
 * @LastEditors: Chp
 * @Reference:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//样式
import './assets/styles/base.css' //基础样式
import './assets/styles/global.scss'

//swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper /* { default global options } */)

//注册全局组件
import plugins from './components/common/index'
Vue.use(plugins)

import api from './api/api'
Vue.prototype.$api = api

Vue.config.productionTip = false

/**
 * @description: 路由发生变化修改页面title
 */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if (to.name == 'Index') {
      document.title = '思利及人公益基金会'
    } else {
      document.title = to.meta.title
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
