<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 13:54:34
 * @LastEditTime: 2021-05-25 15:51:13
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  created () {
    this.getContactUs();
  },
  methods: {
    getContactUs () {
      let params = {
        categoryId: '1376791146797371393'//固定值
      }
      this.$api.getCategoryDetail(params).then(res => {
        if (res.data.data) {
          this.$store.commit('updateConcactUs', res.data.data)
        }
      })
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: SourceHanSansCN-Regular, SourceHanSansCN, "Microsoft YaHei",
    Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
}
.el-notification {
  z-index: 2022 !important;
  top: 106px !important;
}
</style>
